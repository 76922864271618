<template>
  <div>
    <form class="form">

      <div class="form__group">
        <label for="couponName" class="form__label">Coupon Name</label>
        <input
          type="text"
          class="form__input"
          placeholder="e.g END.OF.YEAR.DISCOUNT"
          id="couponName"
          v-model="coupon.name"
        />
      </div>

      <div class="form__group">
        <label for="couponName" class="form__label">Coupon Code</label>
        <input
          type="text"
          class="form__input"
          placeholder="e.g END_DIS"
          id="couponName"
          v-model="coupon.promocode"
        />
      </div>

      <div class="form__group">
        <label for="discountPrice" class="form__label"
          >Discount Price (%)</label
        >
        <input
          type="number"
          class="form__input"
          placeholder="e.g 40%"
          id="discountPrice"
          v-model="coupon.percent_off"
        />
      </div>

      <div class="form__group">
        <label for="duration" class="form__label">Duration</label>
        <select
          name="duration"
          id="duration"
          class="form__input"
          v-model="coupon.duration"
        >
          <option  value="once" selected>Once</option>
          <option  value="repeating">Repeating</option>
          <option  value="forever">Forever</option>
        </select>
      </div>

      <div class="form__group">
        <ca-button :disabled="loading" @click.native="createCoupon">
          Create Coupon
           <span v-if="loading"><i class="fa fa-spinner fa-spin"></i></span>
        </ca-button>
      </div>
    </form>
  </div>
</template>

<script>
import CaButton from "../../../components/buttons/CaButton";
import axios from "../../../../axios";
export default {
  name: "CreateCoupon",
  components: { CaButton },
  data() {
    return {
      loading: false,
      coupon: {
        name:'',
        promocode: "",
        percent_off: "",
        duration: "once",
      },
    };
  },

  methods: {
    validateInput() {
      if (
        this.coupon.name === "" ||
        this.coupon.percent_off === "" ||
        this.coupon.duration === ""||
        this.coupon.promocode === "" 
      ) {
        this.$toast("You cannot leave any field empty", {
          type: "error",
          position: "top-center",
        });
        return false;
      }
      else if(this.coupon.name.indexOf(" ") !== -1||
              this.coupon.promocode.indexOf(" ") !== -1
          ){
           this.$toast("Coupon Name or Promocode should not include space", {
          type: "error",
          position: "top-center",
        });
        return false;
      }

      return true;
    },
    createCoupon() {
      if (!this.validateInput()) return;
      const loading = this.$vs.loading();
      this.loading = true;
      axios
        .post(
          `${this.$store.state.server.requestUri}/admin/coupons`,
          this.coupon
        )
        .then((res) => {
          loading.close();
          this.loading = false;
          this.$toast("Coupon created successfully", {
            type: "success",
            position: "top-center",
          });
          console.log(res);
        })
        .catch((err) => {
          loading.close();
          this.loading = false;
          console.log(err.response);
        });
    },
  },
};
</script>

<style scoped></style>
